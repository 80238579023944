import styled from "styled-components"

import { Checkbox, FormControlLabel } from "@material-ui/core"
import { FormikProps } from "formik"

import { CreateOrganizationStepWrapper } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/organizationCreateWizardStyles"
import { breakpoint } from "src/constants/breakpoints"
import { TProfilingReasons } from "src/data/analytics/types/analyticsTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export type TOrganizationReasonForm = {
  profilingReasons: TProfilingReasons[]
  customProfilingReason: string
}

export const ORG_REASON_FORM_ID = "org-reason-form"

export function OrganizationReasonStep({
  formData,
  isError,
}: {
  formData: FormikProps<TOrganizationReasonForm>
  isError?: boolean
}) {
  const { t } = useTranslate()

  function handleCheck(value: TProfilingReasons, checked: boolean) {
    if (checked) {
      formData.setValues((prev) => ({
        ...prev,
        profilingReasons: [...prev.profilingReasons, value],
      }))
    } else {
      formData.setValues((prev) => ({
        ...prev,
        profilingReasons: prev.profilingReasons.filter((v) => v !== value),
      }))
    }
  }

  const serviceCheckboxes = getCheckboxes(t)

  function renderCheckboxes(checkboxes: TServiceCheckbox[]) {
    const otherIsChecked = formData.values.profilingReasons.includes("Other")

    return checkboxes.map((checkbox) => (
      <div key={checkbox.value}>
        <FormControlLabel
          control={
            <Checkbox
              name="profilingReasons"
              checked={formData.values.profilingReasons.includes(
                checkbox.value
              )}
              onChange={(e) => handleCheck(checkbox.value, e.target.checked)}
            />
          }
          label={checkbox.label}
        />
        {checkbox.value === "Other" && otherIsChecked && (
          <div>
            <MTextField
              name="customProfilingReasons"
              value={formData.values.customProfilingReason}
              onChange={(value) => {
                formData.setValues((prev) => ({
                  ...prev,
                  customProfilingReason: value,
                }))
              }}
              placeholder={`${t(
                langKeys.create_organization_reason_custom_placeholder
              )} (${t(langKeys.input_optional)})`}
            />
          </div>
        )}
      </div>
    ))
  }

  return (
    <CreateOrganizationStepWrapper
      id={ORG_REASON_FORM_ID}
      onSubmit={formData.handleSubmit}
      noValidate
    >
      <MText variant="heading2">
        {t(langKeys.create_organization_reason_for_minut)}
      </MText>
      <CheckboxesWrapper>
        <Checkboxes>{renderCheckboxes(serviceCheckboxes.left)}</Checkboxes>
        <Checkboxes>{renderCheckboxes(serviceCheckboxes.right)}</Checkboxes>
      </CheckboxesWrapper>
      {formData.touched.profilingReasons &&
        formData.errors.profilingReasons && (
          <MBanner type="error">{formData.errors.profilingReasons}</MBanner>
        )}

      {isError && (
        <MBanner type="error">
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      )}
    </CreateOrganizationStepWrapper>
  )
}

type TServiceCheckbox = {
  value: TProfilingReasons
  label: string
}

function getCheckboxes(t: TTranslateFunction): {
  left: TServiceCheckbox[]
  right: TServiceCheckbox[]
} {
  return {
    left: [
      {
        value: "Reduce noise complaints",
        label: t(langKeys.create_organization_reason_reduce_noise_complaints),
      },
      {
        value: "Prevent unwanted parties",
        label: t(langKeys.create_organization_reason_prevent_unwanted_parties),
      },
      {
        value: "Lower the risk of smoking indoors",
        label: t(langKeys.create_organization_reason_lower_smoking_risk),
      },
      {
        value: "Save on utility bills",
        label: t(langKeys.create_organization_reason_save_utility_bills),
      },
    ],
    right: [
      {
        value: "Keep property mold free",
        label: t(langKeys.create_organization_reason_mold_free),
      },
      {
        value: "Reduce maintenance costs",
        label: t(langKeys.create_organization_reason_maintenance_cost),
      },
      {
        value: "Comply with local regulations",
        label: t(langKeys.create_organization_reason_local_regulations),
      },
      {
        value: "Other",
        label: t(langKeys.create_organization_reason_other),
      },
    ],
  }
}

const CheckboxesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing.M};

  @container (${breakpoint.smallUp}) {
    grid-template-columns: 1fr 1fr;
  }
`

const Checkboxes = styled.div`
  display: grid;
  gap: ${spacing.S};
`
